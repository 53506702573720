<template>
    <div>
        <div class="mb-15">
            <label for="domain" class="form-label">{{ label }}</label>
            <input
                type="text"
                class="form-control"
                placeholder="beispiel.de"
                v-model="domain"
                v-on:keyup="typeDomain"
            />
        </div>
        <div class="mb-30">
            <label for="password" class="form-label">Passwort</label>
            <div class="btn-group w-100">
                <input
                    id="password"
                    type="text"
                    class="form-control"
                    v-model="password"
                    readonly
                />
                <button
                    type="button"
                    v-on:click="copyPassword"
                    class="btn btn-link"
                >
                    <i class="lni lni-files"></i>
                </button>
            </div>
        </div>
        <div class="keygen-footer">
            <button
                type="button"
                v-on:click="genkey"
                class="btn btn-primary"
                :disabled="disabled"
            >
                Passwort generieren
            </button>
            <form :action="loginURL" method="post">
                <input type="hidden" name="username" value="admin" />
                <input type="hidden" name="userpass" :value="password" />
                <button
                    formtarget="_blank"
                    class="btn btn-success float-right"
                    v-if="loginBtn"
                    type="submit"
                >
                    Automatisch einloggen <i class="lni lni-arrow-right"></i>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            domain: "",
            regExDomain: "",
            password: "",
            disabled: true,
            loginBtn: false,
            loginURL: "",
        };
    },
    props: ["label"],
    methods: {
        typeDomain: function (event) {
            this.disabled = this.domain.length > 0 ? false : true;
            this.loginBtn = false;

            /* Enter - generiert Passwort */
            if (event.key == "Enter") {
                this.genkey();
            }
        },
        genkey: function () {
            /* Domain to lowercse */
            this.domain = this.domain.toLowerCase();

            /* Domain regex */
            const regex =
                /[-a-zA-Z0-9@:%_\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}(?=\/|$)/;
            let m;

            if ((m = regex.exec(this.domain)) !== null) {
                var vueThis = this;
                axios
                    .post("/projectdomain", {
                        domain: m[0],
                    })
                    .then(function (response) {
                        vueThis.password = response.data["password"];
                        navigator.clipboard.writeText(vueThis.password);
                        Toastr.success("Passwort kopiert!");

                        vueThis.loginBtn = true;
                        vueThis.loginURL = "https://" + m[0] + "/admin/";
                        vueThis.loginUsername = vueThis.password;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                Toastr.error("Keine gültige Domain!");
            }
        },
        copyPassword: function () {
            navigator.clipboard.writeText(this.password);
            Toastr.success("Passwort kopiert!");
        },
    },
};
</script>
