/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

window.Vue = require("vue").default;
window.Toastr = require("toastr");

/* Import jQuery */
import $ from "jquery";
window.$ = window.jQuery = $;

/* jQuery Plugins */
import "slick-carousel/slick/slick.min.js";

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context("./", true, /\.vue$/i);
files
    .keys()
    .map((key) =>
        Vue.component(key.split("/").pop().split(".")[0], files(key).default)
    );

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app",
});

$(document).ready(function () {
    // SEARCH
    $("#search-input").on("keyup", function () {
        // TYPE FILTERING RESET
        if ($("#type-filter button").hasClass("active")) {
            $("#type-filter button").removeClass("active");
            $(".searchable-list li").removeClass("matched");
            $("#projekteverzeichnis .tab-pane").removeClass("filtering");
        }

        var searchText = $(this).val().toLowerCase();
        $("#projekteverzeichnis .tab-pane").toggleClass(
            "searching",
            searchText.length > 0
        );
        $(".searchable-list li").each(function () {
            var listItemText = $(this).text().toLowerCase();
            $(this).toggleClass(
                "matched",
                listItemText.indexOf(searchText) !== -1
            );
        });
    });

    // FILTER
    $("#type-filter button").click(function () {
        // SERACH RESET
        if ($("#search-input").val() != "") {
            $("#search-input").val("");
            $(".searchable-list li").removeClass("matched");
            $("#projekteverzeichnis .tab-pane").removeClass("searching");
        }

        $(this).toggleClass("active");
        $("#projekteverzeichnis .tab-pane").toggleClass(
            "filtering",
            $("#type-filter button.active").length > 0
        );
        let filter = $(this).data("filter");
        let thisFilter = $(this);
        $(".searchable-list li").each(function () {
            if ($(this).find(".project img").data("type") == filter) {
                $(this).toggleClass("matched", thisFilter.hasClass("active"));
            }
        });
    });
});

// ARCHIVE PROJECT
$(function () {
    $(".project-name-transfer").on("click", function (event) {
        $($(this).data("bs-target"))
            .find("[data-href]")
            .data("project-name", $(this).data("project-name"));
        $($(this).data("bs-target"))
            .find(".project-name-txt")
            .text($(this).data("project-name"));

        console.log($($(this).data("bs-target")).find(".project-name-txt"));
    });

    $(".archive-delete-link").on("click", function (event) {
        event.preventDefault();

        let projectName = $(this).data("project-name");
        $.ajax({
            url: $(this).data("href"),
            method: "POST",
            data: { project: projectName },
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (response) {
                $(`[data-project-name="${projectName}"]`)
                    .parents("#item")
                    .remove();
                window.Toastr.success(response.message);
            },
            error: function (xhr) {
                window.Toastr.error(xhr.responseText, "Error");
            },
        });
    });
});

// JIRA
$.ajax({
    url: "https://agentur-77.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/xtxyma/b/8/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=de-DE&collectorId=4864d92b",
    type: "get",
    cache: true,
    dataType: "script",
});

window.ATL_JQ_PAGE_PROPS = {
    triggerFunction: function (showCollectorDialog) {
        $("#jira-trigger").click(function (e) {
            e.preventDefault();
            showCollectorDialog();
        });
    },
};
