<template>
    <div>
        <div class="row">
            <div class="col-md-6 mb-15">
                <label class="form-label">Projektname</label>
                <input
                    type="text"
                    v-model="projectname"
                    placeholder="Projektname"
                    class="form-control"
                    required
                />
            </div>
            <div class="col-md-6 mb-15">
                <label class="form-label">Projekt ID</label>
                <div
                    class="input-outer"
                    :class="{ 'input-danger': !this.projectidIsUnique }"
                >
                    <input
                        type="text"
                        v-model="projectid"
                        v-on:keyup="typeProjectId"
                        v-on:input="onChangeProjectId"
                        placeholder="Projekt ID"
                        class="form-control"
                        required
                    />
                </div>
                <small
                    class="error"
                    :class="{ 'd-none': this.projectidIsUnique }"
                >
                    Projekt ID ist schon vergeben
                </small>
            </div>

            <div class="col-md-6 mb-15">
                <label class="form-label">Projektleiter</label>
                <div class="input-outer">
                    <select
                        class="form-control"
                        v-model="projectManager"
                        v-on:change="selectProjectManager"
                    >
                        <option
                            v-for="(member, index) in this.teamMember"
                            :key="index"
                            :value="member.user.id"
                            v-if="member.user.role !== 4"
                        >
                            {{ member.user.username }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mb-15">
                <label class="form-label">Preis (optional)</label>
                <input
                    type="text"
                    v-model="projectprice"
                    v-on:keyup="typeProjectPrice"
                    placeholder="Preis"
                    class="form-control"
                />
            </div>
            <div class="col-md-6 mb-15">
                <label class="form-label">Tag Farbe (optional)</label>
                <input
                    maxlength="6"
                    type="text"
                    v-model="projectcolor"
                    v-on:keyup="typeProjectColor"
                    placeholder="Hex Farbe"
                    class="form-control"
                />
            </div>
            <div class="col-md-12 mb-15">
                <label class="form-label">Mersprachigkeit (optional)</label>

                <div class="form-check form-check-inline">
                    <label class="form-check-label">
                        <input
                            class="form-check-input"
                            v-model="projectmultilingual"
                            type="checkbox"
                        />
                        Ist das Projekt Mehrsprachig? (optional)
                    </label>
                </div>
            </div>
        </div>
        <div
            v-show="alert"
            class="alert mt-15"
            :class="alertClass"
            role="alert"
        >
            <div class="loading-spinner" v-show="loading">
                <div class="loading-spinner-ele"></div>
                <div class="loading-spinner-ele"></div>
            </div>
            <p v-html="message"></p>
        </div>
        <button
            @click="createProject"
            :disabled="
                this.projectname.length &&
                this.projectid.length > 0 &&
                this.projectidIsUnique
                    ? false
                    : true
            "
            class="btn btn-primary mt-15"
        >
            Projekt erstellen
        </button>
    </div>
</template>

<script>
import toastr from "toastr";

export default {
    created() {
        this.clickUpApi = axios.create({
            baseURL: "/clickup-proxy/",
            withCredentials: true,
        });
        this.onChangeProjectId = _.debounce(this.onChangeProjectId, 500);
    },
    data() {
        return {
            projectname: "",
            projectid: "",
            projectprice: "",
            projectmultilingual: false,
            projectcolor: "",
            projectidIsUnique: true,
            message: "",
            alert: false,
            alertClass: "alert-success",
            loading: false,
            METHOD: { GET: "get", POST: "POST" },
            tags: [],
            projectsSpaceId: 90060228492,
            teamId: 2559588,
            projectManager: "",
            teamMember: [],
            standardProjectManager: 4662810,
        };
    },
    async mounted() {
        this.tags = await this.getSpaceTags(this.projectsSpaceId);
        this.teamMember = await this.getTeamMember();
        this.teamMember = this.teamMember.data.teams[0].members;
        this.projectManager = this.standardProjectManager;
        console.log(this.teamMember);
    },
    methods: {
        createProject: async function () {
            let projectMainResponse;
            try {
                this.setLoading();
                await this.createTag();

                // create Main Project Task
                projectMainResponse = await this.createTaskFromTemplate(
                    900601406878,
                    "t-863grn6kj",
                    this.projectname,
                    true
                );
                await this.addTagToTask(projectMainResponse);
                await this.setCustomFieldToTask(
                    projectMainResponse,
                    "b4923744-a06a-4e33-b27b-d42b7c7f95f0",
                    this.projectid
                ); // ID
                await this.setCustomFieldToTask(
                    projectMainResponse,
                    "04ba4c89-3b93-40ae-9322-550d72a590ed",
                    `https://${this.projectid}.projekteverwaltung.de`
                ); // URL
                await this.setCustomFieldToTask(
                    projectMainResponse,
                    "a979ab08-c602-41ce-84c5-04b3bbc66540",
                    this.projectprice
                ); // Price
                await this.setCustomFieldToTask(
                    projectMainResponse,
                    "3aa191d5-61c5-411c-b5d4-24e4e97152a9",
                    this.projectmultilingual
                ); // Multilingual
                await this.setCustomFieldToTask(
                    projectMainResponse,
                    "1f9dcc3e-8fea-43ce-9de5-97a7930eaa84",
                    { add: [this.projectManager] }
                ); // Project Manager

                // create Project Work Task
                let projectWorkResponse = await this.createTask(
                    900601417960,
                    `📋 ${this.projectid}`
                );
                await this.addTagToTask(projectWorkResponse);

                // linking Tasks
                await this.linkTask(
                    projectMainResponse.data.id,
                    projectWorkResponse.data.id
                );
            } catch (error) {
                this.setError(error);
            }
            this.setSuccess(projectMainResponse);
        },
        createTag: async function () {
            return this.clickUpApi.post(`space/${this.projectsSpaceId}/tag`, {
                tag: {
                    name: this.projectid,
                    tag_fg: "#fff",
                    tag_bg: this.projectcolor
                        ? `#${this.projectcolor}`
                        : this.getRandomColorWithHighContrast(),
                },
            });
        },
        createTaskFromTemplate: async function (listId, templateId, name) {
            return this.clickUpApi.post(
                `/list/${listId}/taskTemplate/${templateId}`,
                {
                    name: name,
                }
            );
        },
        createTask: async function (listId, name) {
            return this.clickUpApi.post(`/list/${listId}/task`, {
                name: name,
                custom_item_id: 1002,
            });
        },
        addTagToTask: async function ({ data }) {
            return this.clickUpApi.post(
                `/task/${data.id}/tag/${this.projectid}`
            );
        },
        setCustomFieldToTask: async function ({ data }, fieldId, value) {
            return this.clickUpApi.post(`/task/${data.id}/field/${fieldId}`, {
                value: value,
            });
        },
        linkTask: async function (taskId, linksTo) {
            return this.clickUpApi.post(`/task/${taskId}/link/${linksTo}`);
        },
        getSpaceTags: function (spaceId) {
            return this.clickUpApi.get(`/space/${spaceId}/tag`);
        },
        getTeamMember: function () {
            return this.clickUpApi.get(`/team`);
        },
        typeProjectId: function () {
            this.projectid = this.projectid
                .toLowerCase()
                .replace(/[^a-z\d_]/g, "");
        },
        typeProjectPrice: function () {
            this.projectprice = this.projectprice.replace(/[^\d]/g, "");
        },
        typeProjectColor: function () {
            this.projectcolor = this.projectcolor.replace(/[^a-fA-F0-9]/g, "");
        },
        selectProjectManager: function () {},
        getRandomColorWithHighContrast: function () {
            // Generate random RGB values
            var r = Math.floor(Math.random() * 256);
            var g = Math.floor(Math.random() * 256);
            var b = Math.floor(Math.random() * 256);

            // Calculate color brightness
            var brightness = (r * 299 + g * 587 + b * 114) / 1000;

            // Check if the color has sufficient contrast with white (brightness difference > 125)
            while (brightness > 125) {
                r = Math.floor(Math.random() * 256);
                g = Math.floor(Math.random() * 256);
                b = Math.floor(Math.random() * 256);
                brightness = (r * 299 + g * 587 + b * 114) / 1000;
            }

            // Convert RGB values to hexadecimal representation
            var hexColor =
                "#" +
                ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);

            return hexColor;
        },
        onChangeProjectId: async function () {
            let $this = this;
            this.projectidIsUnique = !_.find(this.tags.data.tags, {
                name: $this.projectid,
            });
        },
        setError: function (error) {
            this.alert = true;
            this.alertClass = "alert-danger";
            this.message = error;
            this.loading = false;
        },
        setLoading: function () {
            this.alert = true;
            this.alertClass = "alert-info loading-outer";
            this.message = "Projekt wird erstellt";
            this.loading = true;
        },
        setSuccess: async function ({ data }) {
            // Show Success Toastr
            this.loading = false;
            this.alert = false;
            toastr.success(`Projekt erfolgreich erstellt<br>
                <a class='btn btn-success mt-1' href='https://app.clickup.com/t/${this.teamId}/${data.id}'>Projekt Task öffnen</a>`);
            this.tags = await this.getSpaceTags(this.projectsSpaceId);

            // Refresh Tag List
            this.projectname = "";
            this.projectid = "";
            this.projectprice = "";
            this.projectmultilingual = false;
            this.projectcolor = "";
        },
    },
};
</script>
