var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mb-15" }, [
      _c("label", { staticClass: "form-label", attrs: { for: "domain" } }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.domain,
            expression: "domain",
          },
        ],
        staticClass: "form-control",
        attrs: { type: "text", placeholder: "beispiel.de" },
        domProps: { value: _vm.domain },
        on: {
          keyup: _vm.typeDomain,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.domain = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mb-30" }, [
      _c("label", { staticClass: "form-label", attrs: { for: "password" } }, [
        _vm._v("Passwort"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "btn-group w-100" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          staticClass: "form-control",
          attrs: { id: "password", type: "text", readonly: "" },
          domProps: { value: _vm.password },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-link",
            attrs: { type: "button" },
            on: { click: _vm.copyPassword },
          },
          [_c("i", { staticClass: "lni lni-files" })]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "keygen-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button", disabled: _vm.disabled },
          on: { click: _vm.genkey },
        },
        [_vm._v("\n            Passwort generieren\n        ")]
      ),
      _vm._v(" "),
      _c("form", { attrs: { action: _vm.loginURL, method: "post" } }, [
        _c("input", {
          attrs: { type: "hidden", name: "username", value: "admin" },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "userpass" },
          domProps: { value: _vm.password },
        }),
        _vm._v(" "),
        _vm.loginBtn
          ? _c(
              "button",
              {
                staticClass: "btn btn-success float-right",
                attrs: { formtarget: "_blank", type: "submit" },
              },
              [
                _vm._v("\n                Automatisch einloggen "),
                _c("i", { staticClass: "lni lni-arrow-right" }),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }