var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-15" }, [
    _c("label", { staticClass: "form-label" }, [_vm._v("Projektname")]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.projectname,
          expression: "projectname",
        },
      ],
      staticClass: "form-control",
      attrs: { type: "text", placeholder: "Projektname" },
      domProps: { value: _vm.projectname },
      on: {
        keyup: _vm.typeProject,
        input: function ($event) {
          if ($event.target.composing) {
            return
          }
          _vm.projectname = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.alert,
            expression: "alert",
          },
        ],
        staticClass: "alert mt-15",
        class: _vm.alertClass,
        attrs: { role: "alert" },
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "loading-spinner",
          },
          [
            _c("div", { staticClass: "loading-spinner-ele" }),
            _vm._v(" "),
            _c("div", { staticClass: "loading-spinner-ele" }),
          ]
        ),
        _vm._v(" "),
        _c("p", { domProps: { textContent: _vm._s(_vm.message) } }),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.renameAndCreateProject_show,
                expression: "renameAndCreateProject_show",
              },
            ],
            staticClass: "btn btn-danger mt-15",
            on: { click: _vm.renameAndCreateProject },
          },
          [_vm._v("\n            Projekt umbenennen und erstellen\n        ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.goToProject.show,
                expression: "goToProject.show",
              },
            ],
            staticClass: "btn btn-success mt-15",
            attrs: {
              href: "https://" + _vm.projectname + ".projekteverwaltung.de",
              target: "_blank",
            },
          },
          [
            _vm._v("\n            Zum Projekt "),
            _c("i", { staticClass: "lni lni-arrow-right" }),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary mt-15",
        attrs: { disabled: this.projectname.length > 0 ? false : true },
        on: { click: _vm.createProject },
      },
      [_vm._v("\n        Projekt erstellen\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }