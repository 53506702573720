<template>
    <div class="mb-15">
        <label class="form-label">Projektname</label>
        <input
            type="text"
            v-model="projectname"
            v-on:keyup="typeProject"
            placeholder="Projektname"
            class="form-control"
        />
        <div
            v-show="alert"
            class="alert mt-15"
            :class="alertClass"
            role="alert"
        >
            <div class="loading-spinner" v-show="loading">
                <div class="loading-spinner-ele"></div>
                <div class="loading-spinner-ele"></div>
            </div>
            <p v-text="message"></p>
            <button
                v-show="renameAndCreateProject_show"
                @click="renameAndCreateProject"
                class="btn btn-danger mt-15"
            >
                Projekt umbenennen und erstellen
            </button>
            <a
                v-show="goToProject.show"
                :href="'https://' + projectname + '.projekteverwaltung.de'"
                target="_blank"
                class="btn btn-success mt-15"
            >
                Zum Projekt <i class="lni lni-arrow-right"></i>
            </a>
        </div>
        <button
            @click="createProject"
            :disabled="this.projectname.length > 0 ? false : true"
            class="btn btn-primary mt-15"
        >
            Projekt erstellen
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            projectname: "",
            message: "",
            rename: false,
            alert: false,
            alertClass: "alert-success",
            renameAndCreateProject_show: false,
            loading: false,
            goToProject: { show: false },
        };
    },
    methods: {
        createProject: function () {
            let $this = this;

            axios.interceptors.request.use(
                function (config) {
                    $this.alert = true;
                    $this.alertClass = "alert-info loading-outer";
                    $this.message = "Projekt wird erstellt";
                    $this.loading = true;
                    return config;
                },
                function (error) {
                    return Promise.reject(error);
                }
            );

            axios
                .post("/create-vis-project", {
                    projectname: this.projectname,
                    rename: this.rename,
                })
                .then(function (response) {
                    $this.alert = true;
                    $this.message = response.data["msg"];
                    $this.loading = false;

                    if (response.data["status"]) {
                        $this.alertClass = "alert-success";
                        $this.goToProject.show = true;
                    } else {
                        $this.alertClass = "alert-danger";
                        $this.renameAndCreateProject_show =
                            response.data["rename"];
                    }
                })
                .catch(function (error) {
                    $this.alert = true;
                    $this.loading = false;
                    $this.alertClass = "alert-danger";

                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        $this.message = JSON.stringify(
                            error.response.data.message
                        );
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        $this.message = JSON.stringify(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        $this.message = "Error" + JSON.stringify(error.message);
                    }
                });
        },
        renameAndCreateProject: function () {
            this.rename = true;
            this.createProject();
        },
        typeProject: function () {
            this.projectname = this.projectname.toLowerCase();
            this.alert = false;
            this.renameAndCreateProject_show = false;
        },
    },
};
</script>
