var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6 mb-15" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Projektname")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.projectname,
              expression: "projectname",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "Projektname", required: "" },
          domProps: { value: _vm.projectname },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.projectname = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 mb-15" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Projekt ID")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "input-outer",
            class: { "input-danger": !this.projectidIsUnique },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.projectid,
                  expression: "projectid",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "Projekt ID", required: "" },
              domProps: { value: _vm.projectid },
              on: {
                keyup: _vm.typeProjectId,
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.projectid = $event.target.value
                  },
                  _vm.onChangeProjectId,
                ],
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "small",
          { staticClass: "error", class: { "d-none": this.projectidIsUnique } },
          [
            _vm._v(
              "\n                Projekt ID ist schon vergeben\n            "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 mb-15" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("Projektleiter")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-outer" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.projectManager,
                  expression: "projectManager",
                },
              ],
              staticClass: "form-control",
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.projectManager = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.selectProjectManager,
                ],
              },
            },
            _vm._l(this.teamMember, function (member, index) {
              return member.user.role !== 4
                ? _c(
                    "option",
                    { key: index, domProps: { value: member.user.id } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(member.user.username) +
                          "\n                    "
                      ),
                    ]
                  )
                : _vm._e()
            }),
            0
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6 mb-15" }, [
        _c("label", { staticClass: "form-label" }, [
          _vm._v("Preis (optional)"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.projectprice,
              expression: "projectprice",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "Preis" },
          domProps: { value: _vm.projectprice },
          on: {
            keyup: _vm.typeProjectPrice,
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.projectprice = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 mb-15" }, [
        _c("label", { staticClass: "form-label" }, [
          _vm._v("Tag Farbe (optional)"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.projectcolor,
              expression: "projectcolor",
            },
          ],
          staticClass: "form-control",
          attrs: { maxlength: "6", type: "text", placeholder: "Hex Farbe" },
          domProps: { value: _vm.projectcolor },
          on: {
            keyup: _vm.typeProjectColor,
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.projectcolor = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 mb-15" }, [
        _c("label", { staticClass: "form-label" }, [
          _vm._v("Mersprachigkeit (optional)"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-check form-check-inline" }, [
          _c("label", { staticClass: "form-check-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.projectmultilingual,
                  expression: "projectmultilingual",
                },
              ],
              staticClass: "form-check-input",
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.projectmultilingual)
                  ? _vm._i(_vm.projectmultilingual, null) > -1
                  : _vm.projectmultilingual,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.projectmultilingual,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.projectmultilingual = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.projectmultilingual = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.projectmultilingual = $$c
                  }
                },
              },
            }),
            _vm._v(
              "\n                    Ist das Projekt Mehrsprachig? (optional)\n                "
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.alert,
            expression: "alert",
          },
        ],
        staticClass: "alert mt-15",
        class: _vm.alertClass,
        attrs: { role: "alert" },
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "loading-spinner",
          },
          [
            _c("div", { staticClass: "loading-spinner-ele" }),
            _vm._v(" "),
            _c("div", { staticClass: "loading-spinner-ele" }),
          ]
        ),
        _vm._v(" "),
        _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } }),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary mt-15",
        attrs: {
          disabled:
            this.projectname.length &&
            this.projectid.length > 0 &&
            this.projectidIsUnique
              ? false
              : true,
        },
        on: { click: _vm.createProject },
      },
      [_vm._v("\n        Projekt erstellen\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }